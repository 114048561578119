// src/services/auth.js
import axios from "axios";
import Cookies from "js-cookie";
import { initializeNotifications } from "../utils/pushNotification";

const API_URL = "https://api.agroaivision.com";

export const login = async (username, password) => {
  const response = await axios.post(`${API_URL}/auth/login`, {
    username,
    password,
  });
  if (response.status === 200) {
    const { token, expires_timestamp } = response.data;

    // After successful login, initialize notifications
    setTimeout(() => {
      initializeNotifications();
    }, 1000); // Small delay to ensure token is properly set

    return { token, expires_timestamp };
  } else {
    return null;
  }
};

export const setAuthToken = (token, expiresTimestamp, rememberMe) => {
  if (rememberMe) {
    Cookies.set("auth_token", token, {
      expires: new Date(expiresTimestamp),
      secure: true,
      sameSite: "none",
    });
  } else {
    Cookies.set("auth_token", token, { secure: true, sameSite: "strict" });
  }
};

export const getAuthToken = () => {
  return Cookies.get("auth_token");
};

export const removeAuthToken = () => {
  Cookies.remove("auth_token");
};

export const isAuthenticated = () => {
  return !!getAuthToken();
};
